<template>
  <div class="login-container">
    <img src="../../assets/login_logo.png" style="width: 200px; height: auto; margin-top: 70px" />
    <p class="login-hint">请输入手机号后验证登录</p>
    <el-input v-model="user_mobile" type="number" placeholder="请输入手机号码" style="margin-top: 25px" />
    <div style="margin-top: 20px; position: relative; width: 100%; height: 42px">
      <div style="position: absolute; width: 100%">
        <el-input v-model="code" type="number" placeholder="请输入手机号码" />
      </div>
      <p class="login-code" @click="getCode">{{ codeInfo }}</p>
    </div>
    <p class="btn-login" @click="doLogin">登录</p>
  </div>
</template>
<script>
import { getOpenIdForFWH, verifyLoginPin, createLoginPin } from "../../api/keneng";
import { getDefaultKid } from "../../api/app";
import Cookies from 'js-cookie'
import {
  setOpenId,
  setAppUserId,
  setAppLoginToken,
  getOpenId,
  setAppKidId
} from "../../utils/store";
export default {
  name: "index",
  data() {
    return {
      wx_code: this.$route.query.code,
      open_id: "",
      TOTAL_SECONDS: 60,
      user_mobile: "",
      code: "",
      codeInfo: "获取验证码",
      countDown: undefined,
      enableLogin: true,
    };
  },
  beforeDestroy() {
    if (this.countDown) {
      window.clearInterval(this.countDown);
    }
  },
  mounted() {
    if (false) {
      this.open_id = "oOh4h54l3ebT0mNFm-7nMRyb3Nnw";
      setOpenId(this.open_id);
      return;
    }
    if (!this.wx_code) {
      this.enableLogin = false;
      this.$message.error("参数异常～");
      return;
    }
    getOpenIdForFWH(this.wx_code).then((res) => {
      let open_id = res.data.data.openid;
      this.open_id = open_id;
      setOpenId(open_id);
    });
  },
  methods: {
    getCode() {
      if (!this.enableLogin) {
        this.$message.error("获取用户信息出错～");
        return;
      }

      if (this.user_mobile == "") {
        this.$message.warning("请输入手机号码~");
        return;
      }
      if (false) {
        this.startCodeCountDown();
        return;
      }
      createLoginPin(this.user_mobile).then((res) => {
        let result = res.data;
        if (result.Code && result.Code == "OK") {
          this.$message.success("验证码发送成功");
          //开始倒计时
          this.startCodeCountDown();
        } else {
          this.$message.error("发送过于频繁，请稍后重试！");
        }
      });
    },
    startCodeCountDown() {
      var totalSeconds = this.TOTAL_SECONDS + 1;
      this.countDown = setInterval(() => {
        totalSeconds--;
        if (totalSeconds <= 0) {
          clearInterval(this.countDown);
          this.codeInfo = "获取验证码";
        } else {
          this.codeInfo = "倒计时" + totalSeconds + "秒";
        }
      }, 1 * 1000);
    },
    doLogin() {
      if (!this.enableLogin) {
        this.$message.error("获取用户信息出错～");
        return;
      }
      if (this.user_mobile == "") {
        this.$message.error("请输入手机号码~");
        return;
      }
      if (this.code == "") {
        this.$message.error("请输入短信验证码~");
        return;
      }
      let open_id = getOpenId();
      //换成app接口
      verifyLoginPin(this.user_mobile, this.code, "", open_id).then((res) => {
        let result = res.data;
        if (result.code == 0) {
          const user_id = result.data._id.$id;
          const login_token = result.data.login_token;
          setAppUserId(user_id);
          setAppLoginToken(login_token);
          this.requestKidId(user_id);
        } else {
          this.$message.error(result.msg);
        }
      });
    },
    goHome() {
      this.$router.replace({
        path: "/app-home",
      })
    },
    requestKidId(user_id) {
      getDefaultKid(user_id).then((res) => {
        let kid_id = res.data.data._id.$id
        setAppKidId(kid_id)
        this.$message.success("登录成功～");
        let login_redirect = Cookies.get('login-redirect')
        Cookies.set('login-redirect', '')
        if (login_redirect && login_redirect != '') {
          try {
            let values = login_redirect.split('&')
            let path = values[0]
            if (path == 'groupPurchase') {
              this.$router.replace({
                path: '/app-groupPurchase',
                query: {
                  id: values[1]
                }
              })
            } else {
              this.goHome()
            }
          } catch (err) {
            this.goHome()
          }
        } else {
          this.goHome()
        }
      })
    }
  },
};
</script>
<style scoped>
.btn-login {
  text-align: center;
  width: 100%;
  margin-top: 40px;
  cursor: pointer;
  height: 42px;
  line-height: 42px;
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  background: #5d5bb5;
  box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.05);
  border-radius: 26px 26px 26px 26px;
}

.login-container {
  position: relative;
  background-image: url("../../assets/login_bg.png");
  background-size: cover;
  background-color: transparent;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-hint {
  margin-top: 25px;
  font-size: 15px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
}

/deep/ .el-input__inner {
  border-radius: 25px;
  border-width: 0px;
  height: 42px;
}

.login-code {
  right: 28px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  position: absolute;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #5d5bb5;
}
</style>

  